import React, { Component } from 'react';

import './alert-banner.scss';

export default class AlertBanner extends Component {
    constructor() {
        super()
        this.state = {
            timer: 2,
        }
    }

    componentDidMount() {
        const { timer } = this.props;
        this.setState({ timer })
        if (timer > 0) {
            setTimeout(() => {
                window.location.replace("/");
            }, timer * 1000)
        }
        setInterval(() => {
            this.setState({
                timer: this.state.timer - 1
            })
        }, 1000);
    }



    render() {
        const {
            type = 'success',
            title,
            alertFirstText,
            alertSecondText
        } = this.props;
        const { timer } = this.state
        
        return (
            <div className="modal" role="dialog" style={{ display: 'block' }}>
                <div className={`alert-banner ${type} modal-dialog modal-dialog-centered`}>

                    <div className="modal-content">

                        <div className="modal-header custom-alert ">
                            <h3 className="modal-title mt-0 text-danger">{title}</h3>
                        </div>
                        <div className="modal-footer">
                            {timer > 0 && <p>{alertFirstText}<b className="timer-count">{timer}</b> {alertSecondText}</p>}
                        </div>
                    </div>
                </div>
            </div>
            // <div className="alert-banner-overlay">
            //     <div className={`alert-banner ${type}`}>
            //             
            //             <h1>{title}</h1>
            //             

            //     </div>
            // </div>
        );
    };
}