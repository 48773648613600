import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Spinner from "../../../../helpers/spinner";
import axiosFetch from "../../../../helpers/axios";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setGlobalSearchCategory,
  setGlobalSearchText,
  setGlobalLiveSearchResult,
  hideLiveSearchContainer
} from "../../../../actions/MenuDataActionCreators";
import classNames from 'classnames'
import { GET_ALL_PRODUCTS } from "../../../../helpers/constants";

import "./styles.scss";
import closeIcon from "../../../../images/close.png";

function SearchField(props) {
  const [categoryInField, setSearchCategory] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    menuData: { menu },
    //searchCategory,
    setGlobalSearchCategory,
    setGlobalSearchText,
    searchText,
    t,
    lng,
    globalSearchResult,
    setGlobalLiveSearchResult,
    showGlobalSearchContainer,
    hideLiveSearchContainer
  } = props;

  useEffect(() => {
    const getProductsList = () => {
      const productsResult = axiosFetch(GET_ALL_PRODUCTS);
      promiseResolved(productsResult);
    };

    const promiseResolved = (productsResult) => {
      Promise.resolve(productsResult).then((pInfo) => {
        setGlobalLiveSearchResult(pInfo);
      });
    };

    getProductsList()
    promiseResolved()
  }, [setGlobalLiveSearchResult]);



  const renderSearchMenuItems = () => {
    const searchMenu = menu.map((menuItem, index) => {
      const { name, id, nameRo } = menuItem;
      const finalName = lng === "ru" ? name : nameRo;
      return (
        <li
          className="menu-header"
          key={index}
          onClick={() => setSearchCategory({ id, name, nameRo })}
        >
          {finalName}
        </li>
      );
    });
    return searchMenu;
  };

  const redirectToSearchPage = (text) => {
    if (text) {
      setGlobalSearchCategory(categoryInField);
      setIsRedirect(true);
    }
  };

  const setText = (e) => {
    if (e.keyCode === 13) {
      redirectToSearchPage(searchText);
    }
  };

  const liveContent = (productsList) => {
    if (productsList.length === 0) {
      return (
        <div className="live-search-not-found">{t('Products_not_found')}</div>
      )
    }
    const content = (productsList || []).map((searchLine, index) => {
      const { nameRu, nameRo, image, price, url } = searchLine

      const finalName = lng === "ru" ? nameRu : nameRo;
      return (
        <div className="search-line" key={index} >
          <Link to={url} onClick={() => onBlur()}>
            <img src={image} alt={finalName} />
            <div className="live-search-product">{finalName}</div>
            <div className="live-search-price">{price}MDL</div>
          </Link>
        </div>
      );
    });
    return content
  };

  const searchTextFilter = (items, text) => {
    return items.filter((item) => {

      if (lng === 'ru' && categoryInField.id) {
        return (item.descriptionRu.toLowerCase().indexOf(text.toLowerCase()) > -1 && item.category.toLowerCase().indexOf(categoryInField.id.toLowerCase()) > -1);
      } else if (lng === 'ru' && !categoryInField.id) {
        return item.descriptionRu.toLowerCase().indexOf(text.toLowerCase()) > -1;
      } else if (lng === 'ro' && categoryInField.id) {
        return (item.descriptionRo.toLowerCase().indexOf(text.toLowerCase()) > -1 && item.category.toLowerCase().indexOf(categoryInField.id.toLowerCase()) > -1);
      } else {
        return item.descriptionRo.toLowerCase().indexOf(text.toLowerCase()) > -1;
      }
    });
  };

  const renderLiveSearchContainer = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    const visibleItems =
    globalSearchResult && globalSearchResult.length > 0 &&
      searchTextFilter(globalSearchResult, searchText);
    const content = isLoading ? <Spinner /> : showGlobalSearchContainer && liveContent(visibleItems);

    return <div
      className={classNames({
        'live-search': true,
        'empty-container': !showGlobalSearchContainer
      })}
    >
      {content}
    </div>;
  };

  const onBlur = () => {
    setTimeout(() => {
      hideLiveSearchContainer()
    }, 300);

  }

  const finalSearchCategoryName =
    lng === "ru" ? categoryInField.name : categoryInField.nameRo;

  return (
    <div className="col-xs-12 col-sm-12 col-md-7 top-search-holder">
      {isRedirect && <Redirect to="/search" />}
      <div className="search-area">
        <div className="control-group">
          <ul className="categories-filter animate-dropdown">
            <li className="dropdown">
              <a
                className="dropdown-toggle selected-category"
                data-toggle="dropdown"
                href="/"
              >
                {finalSearchCategoryName}
                <b className="caret"></b>
              </a>

              <ul className="dropdown-menu" role="menu">
                {menu && renderSearchMenuItems()}
                <li
                  className="menu-header"
                  key={-1}
                  onClick={() =>
                    setSearchCategory({
                      id: 0,
                      name: "По всему сайту",
                      nameRo: "Pe tot site-ul",
                    })
                  }
                >
                  {t("Search_on_all_site")}
                </li>
              </ul>
            </li>
          </ul>
          <div className="search-container">
            <input
              className="search-field"
              placeholder={t("Search_products")}
              onChange={(e) => setGlobalSearchText(e.target.value)}
              onKeyDown={(e) => setText(e)}
              value={searchText}
              //onBlur={() => onBlur()}
            />
            {searchText && searchText.length > 2 && <div className="close-search">
              <img src={closeIcon} onClick={() => setGlobalSearchText("")} alt={"Close icon"}/>
            </div>}
            {/* <Link to={`/search`}> */}
            <div
              className="search-button"
              onClick={() => redirectToSearchPage(searchText)}
            ></div>
            {/* </Link> */}

            {searchText && searchText.length > 2 && renderLiveSearchContainer()}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  searchCategory: state.MenuDataReducer.searchCategory,
  globalSearchResult: state.MenuDataReducer.globalSearchResult,
  showGlobalSearchContainer: state.MenuDataReducer.showGlobalSearchContainer,
  searchText: state.MenuDataReducer.searchText,
  lng: state.PrefsReducer.lng,

});

export default withRouter(
  connect(mapStateToProps, {
    setGlobalSearchCategory,
    setGlobalSearchText,
    setGlobalLiveSearchResult,
    hideLiveSearchContainer
  })(SearchField)
);
