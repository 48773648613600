import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../../header";
import Footer from "../../footer";
import Categories from "../widgets/categories";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss";

function Contacts(props) {
  const { lng, t, contacts } = props;

  const {
    mapCoordinates,
    officePhoto,
    adresRo,
    adresRu,
    phone,
    phone2,
    email,
  } = contacts;

  const finalAdres = lng === "ru" ? adresRu : adresRo;

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content contacts-page outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
              <div className="contact-page">
                <div className="row">
                  <div>
                    <div className="col-md-5 contact-info">
                      <img src={officePhoto} className="office-photo" alt={finalAdres} />
                      <div className="contact-title">
                        <h3>{t("Contacts")}</h3>
                      </div>
                      <div className="clearfix address">
                        <span className="contact-i">
                          <i className="fa fa-map-marker"></i>
                        </span>
                        <span className="contact-span">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: finalAdres,
                            }}
                          />
                        </span>
                      </div>
                      <div className="clearfix phone-no">
                        <span className="contact-i">
                          <i className="fa fa-mobile"></i>
                        </span>
                        <span className="contact-span">
                          {phone && <a href={`tel:${phone}`}>{phone}</a>}
                          <b>/</b>
                          {phone2 && <a href={`tel:${phone2}`}>{phone2}</a>}
                        </span>
                      </div>
                      <div className="clearfix email">
                        <span className="contact-i">
                          <i className="fa fa-envelope"></i>
                        </span>
                        <span className="contact-span">
                          {email && <a href={`mailto:${email}`}>{email}</a>}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-7 contact-map outer-bottom-vs">
                      <iframe
                        allow="geolocation"
                        width="600"
                        height="450"
                        title={finalAdres}
                        frameborder="0"
                        src={mapCoordinates}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
  contacts: state.AddToCartReducer.contacts,
});

export default withRouter(
  compose(connect(mapStateToProps, {}), withNamespaces())(Contacts)
);
