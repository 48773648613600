import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss"

class BreadCrumbs extends Component {

  render() {
    const { step1, step1Url, step2, step2Url, step3, step3Url, active, t } = this.props;
    return (
      <ol className="breadcrumb hide-phone float-right p-0 m-0">
        <li className="breadcrumb-item">
          <Link to="/">{t('Home')}</Link>
        </li>
        {step1 && <li className={`breadcrumb-item ${active === 1 ? 'active' : ''}`}>
          {active === 1 ? step1 :
            <Link to={step1Url}>{step1}</Link>
          }
        </li>}
        {step2 && <li className={`breadcrumb-item ${active === 2 ? 'active' : ''}`}>
          {active === 2 ? step2 :
            <Link to={step2Url}>{step2}</Link>
          }
        </li>}
        {step3 && <li className={`breadcrumb-item ${active === 3 ? 'active' : ''}`}>
          {active === 3 ? step3 :
            <Link to={step3Url}>{step3}</Link>
          }
        </li>}
      </ol>
    )
  };
}


const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(BreadCrumbs));
