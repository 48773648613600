import React from 'react';
import { Link } from "react-router-dom";
import {
    showCurrencyPrice,
} from "../../../../helpers/constants/functions";
import './styles.scss'

function CartTotal(props) {
    const { cartData: { totalItems, totalSumm }, currency, t } = props


    return (
        <div className="col-md-4 col-sm-12 cart-shopping-total">

            <div className="cart-sub-total">
                <label>{t('Total')}</label>
                <span className="inner-left-md">{showCurrencyPrice(totalSumm, currency)} {currency} <b>x({totalItems})</b></span>
            </div>

            <div className="cart-checkout-btn pull-right">
                <Link to={`/checkout`} className="btn btn-primary checkout-btn">
                    {t('Proceed_to_checkout')}
                </Link>
            </div>
        </div>
    )
}
export default CartTotal
