import React, { useState, useEffect, Fragment } from "react"
import { useParams } from "react-router-dom"
import Header from "../../header"
import Footer from "../../footer"
import Categories from "../widgets/categories"
import Filters from "../widgets/filters"
//import { Link } from "react-router-dom"
import { GET_PRODUCTS_LIST_BY_SUBSUBCAT_ID, GET_SUBSUBCAT_NAME_BY_ID, ITEMS_ON_THE_PAGE } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import Spinner from "../../../helpers/spinner"
import BreadCrumbs from "../../../helpers/breadcrumbs"
import PageTitle from "../../../helpers/page-title"
import Pagination from '../../../helpers/pagination'
import OneProduct from '../../../helpers/one-product'
import HotDeals from "../widgets/hot-deals"

import "./styles.scss"

function Subsubcategories() {
  const { id } = useParams()
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(5000)
  const [isLoading, setIsLoading] = useState(false)
  const [productsList, setProductsList] = useState()
  const [categoryInfo, setCategoryInfo] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    const getProductsList = (page = 0) => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("subSubCategoryId", id)
      formData.append("currentPage", page)
      formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
      formData.append("minValue", minValue)
      formData.append("maxValue", maxValue)

      const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_SUBSUBCAT_ID, formData)
      promiseResolved(productsResult, "productsResult")
    }

    const getSubcategoryInfo = () => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("subSubCategoryId", id)

      const categoryResult = axiosFetch(GET_SUBSUBCAT_NAME_BY_ID, formData)
      promiseResolved(categoryResult, "categoryResult")
    }

    getProductsList()
    getSubcategoryInfo()
    setTotalCount(0)

  }, [id, minValue, maxValue])

  const setRangeCommon = (min, max) => {
    setCurrentPage(0)
    setMinValue(min)
    setMaxValue(max)
  }

  const getProductsList = (page) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append("subSubCategoryId", id)
    formData.append("currentPage", page)
    formData.append("minValue", minValue)
    formData.append("maxValue", maxValue)
    formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)

    const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_SUBSUBCAT_ID, formData)
    promiseResolved(productsResult, "productsResult")
  }



  const promiseResolved = (productsResult, state) => {
    Promise.resolve(productsResult).then((arrList) => {

      switch (state) {
        case "productsResult":
          if (arrList.length) {
            setTotalCount(arrList[0].total)
          }

          setProductsList(arrList)
          break
        case "categoryResult":
          setCategoryInfo(arrList)
          break

        default:
          break
      }

      setIsLoading(false)
    })
  }

  // const range = {
  //   setMin,
  //   setMax,
  // }

  const paginationClick = page => {
    setIsLoading(true)
    setCurrentPage(page)
    getProductsList(page)
  }

  const renderFilterTopBar = () => {
    const { subcategoryName } = categoryInfo[0]
    return (
      <div className="clearfix filters-container m-t-10">
        <div className="row">
          <div className="col col-sm-6 col-md-12">
            <PageTitle text={subcategoryName} />
          </div>
        </div>
      </div>
    )
  }

  const renderProductsItems = (products) => {

    const content = products.map((oneProduct, index) => {
      return <OneProduct oneProduct={oneProduct} showAddToCart={true} key={index} className={"col-sm-6 col-md-4"} />
    })
    return content
  }


  const renderEmptyContainer = () => {
    return (
      <div
        className="col-sm-6 col-md-12 wow fadeInUp animated no-content"
      >
        В этой подкатегории пока ещё нет товаров
      </div>)
  }

  const renderBreadcrumbs = () => {
    const { categoryId, categoryName, subcategoryName, subcategoryId, subsubcategoryName } = categoryInfo[0]
    return (
      <Fragment>
        <BreadCrumbs
          step1={categoryName}
          step1Url={`/category/${categoryId}`}
          step2={subcategoryName}
          step2Url={`/subcategory/${subcategoryId}`}
          step3={subsubcategoryName}
          active={3}
        />
      </Fragment>
    )
  }

  const showPagination = () => {
    return (
      <Pagination
        onClick={paginationClick}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={ITEMS_ON_THE_PAGE}
      />
    )
  }

  const content = isLoading ? <Spinner /> : productsList && productsList.length > 0 ? renderProductsItems(productsList) : renderEmptyContainer()

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
              <Filters setRange={setRangeCommon} />
              <HotDeals />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
              {categoryInfo && renderFilterTopBar()}
              <div className="tab-pane active " id="grid-container">

                <div className="category-product">
                  {categoryInfo && renderBreadcrumbs()}
                  <div className="row">
                    {totalCount > ITEMS_ON_THE_PAGE && showPagination()}
                    {content}
                  </div>
                  <div className="row">
                    {totalCount > ITEMS_ON_THE_PAGE && showPagination()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Subsubcategories

