import {
    LOGIN, 
  //  LOGIN_FAIL, 
    LOGIN_SUCCESS,
} from "./Actions";
//import axios from "../helpers/axios";


export function loginAuth(payload) {
        
        return dispatch => {
            dispatch({type: LOGIN});
            return loadLoginAttempt(dispatch, payload);
        }
}

const loadLoginAttempt = (dispatch, payload) => {

    const loginData = { login: payload  }
    dispatch({
        type: LOGIN_SUCCESS,
        payload: loginData
    })

};