import React from 'react';
import PropTypes from 'prop-types';

const handleClick = (e, { disabled, loading, onPress, onClick }) => {
    if (disabled || loading || e.defaultPrevented) {
      return;
    } else if (onPress) {
      onPress(e);
    } else if (onClick) {
      onClick(e);
    }
  };

const Button = React.forwardRef((props, ref) => {
    const {
      altTag,
      className,
      disabled = false,
      testid,
      titleTag,
      tabIndex = 0,
      type = 'button',
      text,
    } = props;

    return (
      <>                         
        <button
            alt={altTag}
            className={className}
            data-testid={testid}
            disabled={disabled}
            onClick={e => handleClick(e, props)}
            ref={ref}
            tabIndex={tabIndex}
            title={titleTag}
            type={type}
          >
            {text}
        </button>
      </>
     
    );
  });
  
  Button.propTypes = {
    altTag: PropTypes.string,
    dangerous: PropTypes.bool,
    disabled: PropTypes.bool,
    large: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    onPress: PropTypes.func,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    square: PropTypes.bool,
    title: PropTypes.string,
    titleTag: PropTypes.string,
    transparent: PropTypes.bool,
  };
  
  export default Button;