import {
    GLOBAL_SETTINGS
} from "./Actions";

export function globalSettings(payload) {
    return dispatch => {
        return loadGlobalSettings(dispatch, payload);
    }
}

const loadGlobalSettings = (dispatch, payload) => {
    dispatch({
        type: GLOBAL_SETTINGS,
        payload
    })
};
