import React, { useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import CartTable from "./cart-table";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertBanner from "../../../helpers/alert-banner";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss";

function Checkout(props) {
	const { cartItems, cartData, currency, t, lng } = props;
	const [redirect, isRedirect] = useState(false)
	const tableOrEmptyContent = cartItems && cartItems.length > 0

	return (
		<div className="cnt-home">
			<Header />
			<div className="body-content outer-top-xs">
				<div className="container">
					{redirect && <AlertBanner
						type={"success"}
						fontAwesomeType={"fa-check"}
						title={t('Success_alert')}
						timer={5}
						alertFirstText={t('Redirect')}
						alertSecondText={t('Seconds')}
					/>}
					<div className="row">

						<div className="shopping-cart">
							<h3>{t('Your_order')}</h3>
							{tableOrEmptyContent ?
								<CartTable
									{...{ cartItems }}
									{...{ currency }}
									{...{ cartData }}
									{...{t}}
									{...{lng}}
									redirect={() => isRedirect(true)}
								/> :
								<div className="checkout-empty">{t('Nothing')}</div>}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

const mapStateToProps = (state) => ({
	cartItems: state.AddToCartReducer.cartItems,
	cartData: state.AddToCartReducer.cartData,
	currency: state.MenuDataReducer.currency,
});


export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(Checkout));
