import {
    ADD_TO_CART_START,
    ADD_TO_CART_SUCCESS,
    SET_TOKEN,
    GET_CART_ITEMS_START,
    GET_CONTACTS
} from "../actions/Actions";

const initialState = {
    isFetching: false,
    cartData: {
        totalItems: 0,
        totalSumm: 0
    },
    cartItems:{},
    tokenId: "",
    contacts: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART_START:
            return Object.assign({}, {
                ...state,
                isFetching: true,
            });
        case GET_CONTACTS:
            return Object.assign({}, {
                ...state,
                contacts: action.payload,
            });
        case ADD_TO_CART_SUCCESS:
            return Object.assign({}, {
                ...state,
                isFetching: false,
                cartData: action.payload,
            });
            case GET_CART_ITEMS_START:
                return Object.assign({}, {
                    ...state,
                    isFetching: false,
                    cartItems: action.payload,
                });
        case SET_TOKEN:
            
            return Object.assign({}, {
                ...state,
                tokenId: action.payload,
            });
            
        // case LOGIN_FAIL:
        //     return Object.assign({}, {
        //         ...state,
        //         isFetching: false,
        //         loginData: {},
        //     });
        default:
            return state;
    }
}
