import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { GET_SLIDER_ITEMS } from '../../../../helpers/constants'
import axiosFetch from "../../../../helpers/axios"
import Spinner from "../../../../helpers/spinner"

import './styles.scss'

function Slider() {

  const [isLoading, setIsLoading] = useState(false)
  const [sliderList, setSliderList] = useState([])


  useEffect(() => {
    const getSliderList = () => {
      setIsLoading(true)

      const sliderResult = axiosFetch(GET_SLIDER_ITEMS)
      promiseResolved(sliderResult)
    }
    getSliderList()
  }, [])

  const promiseResolved = (sliderResult) => {
    Promise.resolve(sliderResult).then((sliderInfo) => {
      setSliderList(sliderInfo)
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }

  const renderSliderContent = (images) => {
    const content = images.map((oneImage, index) => {
      const { sliderImage } = oneImage

      return (
        <div key={index}>
          <img src={sliderImage} alt="" />
        </div>
      )
    })

    return content
  }

  const content = isLoading ? <Spinner /> : renderSliderContent(sliderList)



  return (
    <div className="carousel-container">
      <Carousel showArrows={true} emulateTouch={true} showIndicators={false} showThumbs={false} infiniteLoop={true}>
        {content}
      </Carousel>
    </div>
  )
}
export default Slider
