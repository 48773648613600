import {combineReducers} from "redux";
import LoginReducer from "./LoginReducer";
import MenuDataReducer from "./MenuDataReducer";
import SetLoginReducer from "./SetLoginReducer";
import AddToCartReducer from "./AddToCartReducer";
import GlobalSettingsReducer from "./GlobalSettingsReducer";
import PrefsReducer from "./PrefsReducer";

const rootReducer = combineReducers({
    LoginReducer,
    MenuDataReducer,
    SetLoginReducer,
    AddToCartReducer,
    GlobalSettingsReducer,
    PrefsReducer
});

export default rootReducer;
