import {
    SET_LOGIN,
} from "./Actions";

export function setLoginAuth(payload) {
    return dispatch => {
        return setLoginAttempt(dispatch, payload);
    }
}

const setLoginAttempt = (dispatch, payload) => {

    dispatch({
        type: SET_LOGIN,
        payload
    })
};