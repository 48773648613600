import React, { useState, useEffect } from 'react';
import Spinner from "../../../../helpers/spinner"
import { USER_REGISTRATION } from '../../../../helpers/constants'
import Input from '../../../../helpers/input'
import Button from '../../../../helpers/button'
import axiosFetch from "../../../../helpers/axios"
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setLoginAuth } from "../../../../actions/SetLoginAuthActionCreators"
import { isValidEmail } from "../../../../helpers/constants/functions"
import CookieHelper from "../../../../helpers/cookie-helper";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import './styles.scss'

function RegForm(props) {

    const { setLoginAuth, t } = props

    const [registrationData, setRegistrationData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [isRedirect, setIsRedirect] = useState(false)

    useEffect(() => {
        const userEmail = CookieHelper("get", "userEmail");
        if (userEmail) {
            setIsRedirect(true)
        }
    }, [])

    const setValue = (param, value) => {
        setError()
        const data = {
            ...registrationData,
            [param]: value
        }
        setRegistrationData(data)
    }

    const setErrorAndRemoveLoading = (errorValue) => {
        setError(errorValue)
        setIsLoading(false)
    }


    const registration = () => {
        setIsLoading(true)
        const { email, password, name, secondPassword } = registrationData
        const emailRegex = isValidEmail(email)
        if (!emailRegex) {
            setErrorAndRemoveLoading(t('Email_error'))
        } else if (password !== secondPassword) {
            setErrorAndRemoveLoading(t("Password_mismatch"))
        } else {
            setTimeout(() => {
                userReg(email, password, name)
            }, 500);
        }
    }

    const userReg = (email, password, name) => {

        const formData = new FormData()
        formData.append("email", email)
        formData.append("password", password)
        formData.append("name", name)

        const response = axiosFetch(USER_REGISTRATION, formData)
        promiseResolved(response)

        setIsLoading(false)
    }

    const promiseResolved = (response) => {
        Promise.resolve(response).then((resArr) => {

            if (resArr.error) {
                setError(resArr.error)
            } else {
                const { userInfo: { userName, userEmail, userPicture, userId } } = resArr

                CookieHelper("set", "userEmail", userEmail);
                CookieHelper("set", "userName", userName);

                const loginDataObj = {
                    name: userName,
                    email: userEmail,
                    picture: userPicture,
                    id: userId
                }

                setLoginAuth(loginDataObj)
                setIsRedirect(true)
            }
        })
        setIsLoading(false)
    }


    const { email, name, password, secondPassword } = registrationData
    const renderContent = () => {
        return (
            <div>
                {isRedirect && <Redirect to="/" />}
                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputEmail2">{t("Your_email")} <span>*</span></label>
                    <Input
                        type="email"
                        id="register-email"
                        value={email}
                        className="form-control unicase-form-control text-input"
                        onChange={(e) => setValue("email", e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputEmail1">{t("Your_name")}  <span>*</span></label>

                    <Input
                        type="text"
                        id="register-name"
                        value={name}
                        className="form-control unicase-form-control text-input"
                        onChange={(e) => setValue("name", e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputEmail1">{t("Your_password")} <span>*</span></label>

                    <Input
                        type="password"
                        id="register-password"
                        value={password}
                        className="form-control unicase-form-control text-input"
                        onChange={(e) => setValue("password", e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputEmail1">{t('The_password_again')} <span>*</span></label>
                    <Input
                        type="password"
                        id="register-secondPassword"
                        value={secondPassword}
                        className="form-control unicase-form-control text-input"
                        onChange={(e) => setValue("secondPassword", e.target.value)}
                    />
                </div>
                {error && <div className="error-container">{error}</div>}
                <Button
                    type="button"
                    className="btn-upper btn btn-primary "
                    text={t('Registration')}
                    disabled={!secondPassword || !password || !name || !email}
                    onClick={() => registration()}
                />
                
            </div>
        )
    }


    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="login-form">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {
    setLoginAuth,
}),withNamespaces())(RegForm));

