import React, { useState, useEffect } from 'react';
import Header from "../../header";
import Footer from "../../footer";
import Categories from "../widgets/categories"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { Redirect } from "react-router-dom";
import { GET_ALL_PRODUCTS_BY_SEARCH_TEXT } from '../../../helpers/constants'
import axiosFetch from "../../../helpers/axios"
import Spinner from "../../../helpers/spinner"
import OneProduct from '../../../helpers/one-product'
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import './styles.scss'


function Checkout(props) {
  const { searchText, searchCategory, t } = props

  const [isLoading, setIsLoading] = useState(false)
  const [productsList, setProductsList] = useState([])
  //const [totalCount, setTotalCount] = useState(0)
  //const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    const getProductsListBySearchText = (page = 0) => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("searchText", searchText)
      formData.append("searchCategoryName", searchCategory.name)
      formData.append("searchCategoryId", searchCategory.id)
      //formData.append("currentPage", page)
      //formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
      formData.append("minValue", 0)
      formData.append("maxValue", 10000)
      const productsResult = axiosFetch(GET_ALL_PRODUCTS_BY_SEARCH_TEXT, formData)
      promiseResolved(productsResult)
    }
    getProductsListBySearchText()
  }, [searchText, searchCategory])

  const promiseResolved = (productsResult) => {
    Promise.resolve(productsResult).then((pInfo) => {
      setProductsList(pInfo)
    })
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }
/*
  let category = "не выбрано"
  if (searchCategory.name) {
    category = searchCategory.name
  }*/
  //const value = textInField

  const renderProductsItems = (products) => {

    const content = products.map((oneProduct, index) => {
      return <OneProduct oneProduct={oneProduct} showAddToCart={true} key={index} className={"col-sm-6 col-md-3"} />
    })
    return content
  }

  const renderEmptyContainer = () => {
    return (
      <div
        className="col-sm-6 col-md-12 wow fadeInUp animated no-content"
      >
        {t('Products_not_found')}
      </div>)
  }

  const content = isLoading ? <Spinner /> : productsList && productsList.length > 0 ? renderProductsItems(productsList) : renderEmptyContainer()
  const total = productsList[0] ? productsList[0].total : 0
  return (
    <div className="cnt-home">

      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 search-page">
              <div className="page-title">{t('Was_found', {total})}</div>
              {content}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  searchText: state.MenuDataReducer.searchText,
  searchCategory: state.MenuDataReducer.searchCategory,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(Checkout));
