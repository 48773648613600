import React from 'react';
import Header from "../../header";
import Footer from "../../footer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import LoginForm from "./login-form"
import RegForm from "./reg-form"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import './styles.scss'

function Autorization(props) {
  const { t } = props

  const renderLoginForm = () => {
    return (
      <div>
        <h4 className="">{t('Autorization')}</h4>
        <p className="">{t('Long_time')}</p>
        <LoginForm />
      </div>
    )
  }

  const renderRegistrationForm = () => {
    return (
      <div>
        <h4 className="checkout-subtitle">{t("Create_new_account")}</h4>
        <RegForm />
      </div>
    )
  }

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">

          <div className="body-content">
            <div className="container">
              <div className="sign-in-page">
                <div className="row">
                  <div className="col-md-6 col-sm-6 sign-in">
                    {renderLoginForm()}
                  </div>
                  <div className="col-md-6 col-sm-6 create-new-account">
                    {renderRegistrationForm()}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}


const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {
  setLoginAuth,
}),withNamespaces())(Autorization));
