export const APP_TITLE = "BooGoo Shop";
export const SITE_URL = "http://localhost:3000/";
export const API_URL = 'https://eshop-admin.ssv-design.com/Main';
export const BASE_URL = 'https://eshop-admin.ssv-design.com/';

export const NO_IMAGE_URL = BASE_URL + 'uploads/no-image.png';

export const ONE_PRODUCT_EMPTY_DATA = {}

export const ITEMS_ON_THE_PAGE = 12;
export const RELOAD_TIMER = 3;
export const USD_VALUE = 17.66;
export const EURO_VALUE = 21.37;


export const SALES = "Товар на скидках"
export const RECOMAND = "Рекомендуемый товар"
export const WEEK = "Товар недели"
export const NEW = "Скидка"
export const DISCOUNT = "Популярный товар"

export const SALES_ID = 8
export const RECOMAND_ID = 9
export const WEEK_ID = 10
export const NEW_ID = 11
export const DISCOUNT_ID = 12
export const TIMER = 7

export const GET_CATEGORIES_LIST = API_URL + "/getCategoriesList" // Получение всех категори с их подкатегориями

export const GET_PRODUCTS_LIST_BY_CAT_ID = API_URL + "/getProductsListByCategoryId" //Получение списка товаров категории
export const GET_CAT_NAME_BY_ID = API_URL + "/getCategoryInfoById" // Получение информации о категории

export const GET_PRODUCTS_LIST_BY_SUBCAT_ID = API_URL + "/getProductsListBySubCategoryId"  //Получение списка товаров по подкатегории
export const GET_SUBCAT_NAME_BY_ID = API_URL + "/getSubcategoryInfoById" // Получение информации о подкатегории

export const GET_PRODUCTS_LIST_BY_SUBSUBCAT_ID = API_URL + "/getProductsListBySubSubCategoryId" //Получение списка товаров по подПодкатегории
export const GET_SUBSUBCAT_NAME_BY_ID = API_URL + "/getSubSubcategoryInfoById" // Получение информации о подПодкатегории

export const GET_PRODUCT_INFO_BY_ID = API_URL + "/getProductInfoById" // Получение информации о товаре

export const GET_PRODUCTS_LIST_BY_OPTION_ID = API_URL + "/getProductsListByOptionId" // получение списка товаров по опции (Лучший товар, товар недели, на скидках)

export const GET_SLIDER_ITEMS = API_URL + "/getSliderItems" //Получение всего контента слайдера

export const USER_FB_LOGIN = API_URL + "/userFbLogin" //Авторизация через FB

export const USER_LOGIN = API_URL + "/userLogin" //Авторизация через форму входа

export const USER_REGISTRATION = API_URL + "/userRegistration" //Регистрация юзера

export const ADD_ITEM_TO_CART = API_URL + "/addItemToCart" //Добавление Одного товара в корзину

export const GET_CART_DATA = API_URL + "/getCartData" //Получение информации по корзине Всего:ХХХ шт Итого: YYY MDL

export const GET_CART_ITEMS = API_URL + "/getCartItems" // Получение всего списка корзины по Токену

export const DELETE_CART_ITEM = API_URL + "/deleteCartItem" // удаление одного товара из корзины

export const CART_UPDATE_ITEM_COUNT = API_URL + "/cartUpdateItemCount" // изменение кол-ва товаров в корзине 

export const GET_ALL_PRODUCTS_BY_SEARCH_TEXT = API_URL + "/getAllProductsBySearchText" // поиск

export const PAGE_ABOUT_US_GET_CONTENT = API_URL + "/pageAboutUsGetContent" // информация для страницы О нас

export const GET_GLOBAL_SETTINGS = API_URL + "/getGlobalSettings" // получение глобальных настроек сайта

export const SET_ORDER = API_URL + "/setOrder" // добавление заказа на сайтe
export const SET_ORDER_ITEMS = API_URL + "/setOrderItems" // добавление товаров заказа
export const DELETE_CART_ITEMS = API_URL + "/deleteCartItems" // удаление всех товаров из корзины после оформления заказа 
export const SEND_NOTIFICATION_TO_ADMIN = API_URL + "/sendNotificationToAdmin" // отправка письма администратору сайта
export const GET_CONTACTS_INFO = API_URL + "/getContactsInfo" // Получение информации для страницы - контакты
export const PAGE_GET_CONTENT_GET_CONTENT = API_URL + "/pageDeliveryGetContent" // Получение информации для страницы - контакты
export const GET_ALL_PRODUCTS = API_URL + "/getAllProducts" // Получение информации для страницы - контакты
