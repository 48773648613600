import React from "react";
import Header from "../../header";
import Footer from "../../footer";
import CartTable from "./cart-table";
import CartTotal from "./cart-total";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axiosFetch from "../../../helpers/axios"
import CookieHelper from "../../../helpers/cookie-helper";
import { getCartData, getCartInfoFirstLoad } from "../../../actions/AddToCartActionCreator"
import { DELETE_CART_ITEM } from "../../../helpers/constants"
import emptyCard from "../../../images/empty-cart.png"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss";

function Cart(props) {
  const { cartItems, cartData, currency, getCartData, getCartInfoFirstLoad, t, lng } = props;
  const token = CookieHelper("get", "token");
  const deleteItemFromCart = (deleteProductId) => {

    const formData = new FormData();
    formData.append("lineId", deleteProductId);

    axiosFetch(DELETE_CART_ITEM, formData).then(() => {
      refreshData()
    });
  };

  const refreshData = () => {

    getCartData(token);
    getCartInfoFirstLoad(token);
  }

  const renderEmptyCartContainer = () => {
    return (
      <div className="empty-container-title">
        <h3>{t('Cart_empty')}</h3>
        <img src={emptyCard} alt={t('Cart_empty')} />
      </div>

    )
  }

  const tableOrEmptyContent = cartItems.length > 0

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="shopping-cart">
              {tableOrEmptyContent ?
                <CartTable
                  {...{ cartItems }}
                  {...{ currency }}
                  {...{ token }}
                  {...{ deleteItemFromCart }}
                  {...{ refreshData }}
                  {...{ t }}
                  {...{ lng }}
                /> :
                renderEmptyCartContainer()}
              {tableOrEmptyContent &&
                <CartTotal
                  {...{ cartData }}
                  {...{ currency }}
                  {...{ t }}
                  {...{ lng }}
                />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.AddToCartReducer.cartItems,
  cartData: state.AddToCartReducer.cartData,
  currency: state.MenuDataReducer.currency,
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {
  getCartData,
  getCartInfoFirstLoad
}), withNamespaces())(Cart));
