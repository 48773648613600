import {
    SET_LOGIN,
} from "../actions/Actions";

const initialState = {
    loginData: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LOGIN:
            return Object.assign({}, {
                ...state,
                loginData: action.payload,
            });
        default:
            return state;
    }
}
