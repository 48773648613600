import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";

function Categories(props) {
  const {
    menuData: { menu },
    currentCategory,
    lng
  } = props;

  const renderCategories = () => {
    const content = menu.map((menuItem, index) => {
      const { name, url, id, categoryImage, nameRo } = menuItem;
      
      const activeClass = currentCategory === id ? "active-category" : ""
      const finalTitle = lng === "ru" ? name : nameRo
      return (
        <li className={`dropdown menu-item ${activeClass}`} key={index} >
           <Link to={url}>
             <img src={categoryImage} className={`category-image ${activeClass}`} alt={finalTitle}/>
            {finalTitle}
          </Link>
        </li>
      );
    });

    return content;
  };

  return (
    <div className="side-menu ">
      <nav className="yamm " role="navigation">
        <ul className="nav">{menu && renderCategories()}</ul>
      </nav>
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
});

export default withRouter(connect(mapStateToProps, {})(Categories));
