import translationRO from "./translate/ro/languages";
import translationRU from "./translate/ru/languages";
import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import {getPrefLang, isPrefLangPresent} from "./services/Prefs";

// the translations
const resources = {
    ro: {
        translation: translationRO
    },
    ru: {
        translation: translationRU
    }
};

i18n.use(reactI18nextModule)
    .init({
        resources,
        lng: isPrefLangPresent() ? getPrefLang() : "ro",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
