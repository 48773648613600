import React, { useState } from 'react';
import { SET_ORDER, SET_ORDER_ITEMS, DELETE_CART_ITEMS, SEND_NOTIFICATION_TO_ADMIN } from "../../../../../helpers/constants"
import axiosFetch from "../../../../../helpers/axios"
import CookieHelper from "../../../../../helpers/cookie-helper";
import { isValidEmail } from "../../../../../helpers/constants/functions";
import { getCartData, getCartInfoFirstLoad } from "../../../../../actions/AddToCartActionCreator"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../../../../../helpers/spinner";

import './styles.scss'

function DeliveryForm(props) {
    const { cartItems, totalSumm, deliveryPriceFinal, totalSummFinal, deliveryType, redirect, t } = props
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [note, setNote] = useState("")
    const [count, setCount] = useState(1)
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabledButton] = useState(false)

    const changeCount = (action = "inc") => {
        if (action === "inc") {
            setCount(count + 1)
        } else if (count > 1) {
            setCount(count - 1)
        }
    }

    const setErrorAndRemoveLoading = errorValue => {
        setError(errorValue)
        setIsLoading(false)
    }

    const setOrder = () => {
        setIsLoading(true)
        
        const emailRegex = isValidEmail(email)
        if (email.length > 0 && !emailRegex) {
            setErrorAndRemoveLoading(t('Email_error'))
            return false
        }
        setIsDisabledButton(true)

        const userId = 0

        const token = CookieHelper("get", "token");
        const formData = new FormData();
        formData.append("userId", userId)
        formData.append("name", name)
        formData.append("phone", phone)
        formData.append("email", email)
        formData.append("note", note)
        formData.append("count", count)
        formData.append("token", token)
        formData.append("deliveryType", deliveryType)
        formData.append("totalSumm", totalSumm)
        formData.append("deliveryPriceFinal", deliveryPriceFinal)
        formData.append("totalSummFinal", totalSummFinal)

        let totalCartItems = 0

        axiosFetch(SET_ORDER, formData).then(orderId => {
            cartItems.forEach(cartLine => {
                totalCartItems++
                const { id, price, count } = cartLine;

                const fd = new FormData();

                fd.append("orderId", orderId)
                fd.append("productId", id)
                fd.append("productPrice", price)
                fd.append("productCount", count)

                axiosFetch(SET_ORDER_ITEMS, fd).then(() => {
                    if (totalCartItems === cartItems.length) {

                        const delFormData = new FormData();
                        
                        delFormData.append("token", token)
                        
                        axiosFetch(DELETE_CART_ITEMS, delFormData).then(() => {
                            setIsLoading(false)
                            redirect()
                            setIsDisabledButton(false)
                        })

                        const sendData = new FormData();
                        
                        sendData.append("orderId", orderId)
                        axiosFetch(SEND_NOTIFICATION_TO_ADMIN, sendData)

                    }
                })
            })
        })

    }

    const setNameCheck = (value) => {
        const regex = /^[a-zA-Zа-яА-Я]{0,20}$/
        if(regex.test(value)){
            setName(value)
        }
    }

    const setPhoneCheck = (value) => {
        const regex = /^[0-9]+$/
        if((regex.test(value) && value.length <= 11) || value === ""){
            setPhone(value)
        }
    }

    const renderContent = () => {
        return (
            <div className="contact-form">
                <div className="col-md-12 contact-title">
                    <h4>{t('Order_form')}</h4>
                </div>
                <div className="col-md-3 ">
                   
                    <div className="form-group">
                        <label className="info-title">
                            {t('Your_name')}<span>*</span>
                        </label>
                        <input type="email"
                            className="form-control unicase-form-control text-input"
                            placeholder={t('Your_name')}
                            value={name}
                            onChange={(e) => { setError(); setNameCheck(e.target.value) }}
                        />
                    </div>

                </div>
                <div className="col-md-3">

                    <div className="form-group">
                        <label
                            className="info-title" >
                            {t('Phone')}<span>*</span>
                        </label>
                        <input
                            type="number"
                            className="form-control unicase-form-control text-input"
                            placeholder={t('Phone')}
                            value={phone}
                            onChange={(e) => { setError(); setPhoneCheck(e.target.value) }}
                        />
                    </div>

                </div>
                <div className="col-md-3">

                    <div className="form-group">
                        <label className="info-title">
                            Email
                        </label>
                        <input
                            type="email"
                            className="form-control unicase-form-control text-input"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => { setError(); setEmail(e.target.value) }}
                        />
                    </div>

                </div>
                <div className="col-md-3">

                    <p className="form-row form-row-first mx-number woocommerce-validated">
                        <span className="minus" onClick={() => { setError(); changeCount("dec") }}>-</span>
                        <label className="">{t('Cutlery')}</label>
                        <span className="woocommerce-input-wrapper">
                            <input type="number" className="input-text " name="shipping_cutlery" placeholder="" value={count} min="0" max="10" />
                        </span>
                        <span className="plus" onClick={() => { setError(); changeCount() }}>+</span>
                    </p>

                </div>


                <div className="col-md-12">

                    <div className="form-group">
                        <label className="info-title">
                            {t('Adres')}<span>*</span>
                        </label>
                        <textarea className="form-control unicase-form-control" onChange={(e) => { setError(); setNote(e.target.value) }} value={note} />
                    </div>

                </div>
                {error && <div className="col-md-12 send-order-error-container">{error}</div>}
                <div className="col-md-12 outer-bottom-small m-t-20">
                    <button type="submit"
                        className="btn-upper btn btn-primary checkout-page-button"
                        disabled={!note || !phone || !name || isDisabled}
                        onClick={() => setOrder()}
                    >
                        {t('Set_order')}
                        </button>
                </div>
            </div>
        )
    }

    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="login-form">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    cartData: state.AddToCartReducer.cartData,
});

export default withRouter(connect(mapStateToProps, {
    getCartData,
    getCartInfoFirstLoad
})(DeliveryForm));
