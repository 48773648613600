import React from 'react';
import Header from "../../header";
import Footer from "../../footer";
import Categories from "../widgets/categories"

import './styles.scss'

function MyFavorites() {

    return (
        <div className="cnt-home">
        <Header />
        <div className="body-content outer-top-xs">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
                Main Content / MyFavorites
              </div>
            </div>
           
          </div>
        </div>
        <Footer />
      </div>
    )
}
export default MyFavorites
