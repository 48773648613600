import React, { useState } from "react";
import { Link } from "react-router-dom";
import { /*SALES, RECOMAND, WEEK, NEW, DISCOUNT,*/ NO_IMAGE_URL } from "../constants"
import { showCurrencyPrice } from "../constants/functions"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../spinner"
import { withSnackbar } from 'notistack';
import { addItemToMyCart, getCartData } from "../../actions/AddToCartActionCreator"
import CookieHelper from "../../helpers/cookie-helper";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./one-product.scss"

function OneProduct(props) {

  const {
    oneProduct:
    {
      nameRu,
      nameRo,
      price,
      oldPrice,
      image,
      id,
      //labels,
      descriptionRu,
      descriptionRo,
      sostav, //это граммаж
      count,
      available
    },
    className,
    showAddToCart,
    currency,
    enqueueSnackbar,
    addItemToMyCart,
    getCartData,
    t,
    lng
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [showDescription, setShowDescription] = useState("")

  const imgUrl = image ? image : NO_IMAGE_URL
  const finalPrice = price ? price : 0

  const finalProductName = lng === "ru" ? nameRu : nameRo
  const finalProductDescription = lng === "ru" ? descriptionRu : descriptionRo


  const finalName = finalProductName ? finalProductName : t('Not_filled')

  // const switchLabels = (label) => {
  //   let labelClass

  //   switch (label) {
  //     case SALES:
  //       labelClass = "sales"
  //       break;
  //     case RECOMAND:
  //       labelClass = "recomand"
  //       break;
  //     case WEEK:
  //       labelClass = "week"
  //       break;
  //     case NEW:
  //       labelClass = "new"
  //       break;
  //     case DISCOUNT:
  //       labelClass = "discount"
  //       break;

  //     default:
  //       break;
  //   }
  //   return labelClass
  // }

  // const renderLabel = (labels) => {
  //   const content = labels.map((labels, index) => {

  //     const { labelName } = labels
  //     const labelClass = switchLabels(labelName)

  //     return <div className={`tag ${labelClass}`} title={labelName} key={index} />
  //   })

  //   return content
  // }

  const addItemToCart = (id, finalName, price) => {
    setIsLoading(true)
    const token = CookieHelper("get", "token");
    setTimeout(() => {
      enqueueSnackbar(t('Been_added', { finalName }));
      const cartData = {
        id,
        finalName,
        count: 1,
        price,
        token
      }
      addItemToMyCart(cartData)
      setIsLoading(false)

      setTimeout(() => {
        getCartData(token)
      }, 1000);

    }, 500);
  }

  const addToCart = () => {
    return (
      <div className="animate-effect">
        <div className="action">
          <button
            className="btn btn-primary icon"
            data-toggle="dropdown"
            type="button"
            disabled={Number(available) === 0}
            style={{ width: "100%" }}
            onClick={() => addItemToCart(id, finalName, price)}
          >
            {Number(available) === 0 ? t('Product_unavailable') : t('To_cart')}
          </button>
        </div>
      </div>
    )
  }

  const getPercentDiscount = (price, oldPrice) => {
    const percent = -1 * (100 - (price * 100 / oldPrice))
    return percent.toFixed()
  }

  const renderContent = () => {
    return (

      <div className="product">
        {isLoading && <Spinner />}
        <div className="product-image">
          {showDescription &&
            <div className="show-description-popup">
              <div className="close-icon" onClick={() => setShowDescription(false)}>X</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: finalProductDescription
                }}></div>

            </div>
          }
          <div className="image">

            <Link to={`/details/${id}`}>
              <div
                className="product-image-container"
                style={{ backgroundImage: `url(${imgUrl})` }}
              />
            </Link>
          </div>
          {/* <div className="label-container">
            {labels && renderLabel(labels)}
          </div> */}



        </div>

        <div className="product-info text-left">
          <div className="product-description">
            <span className="popup-description" onClick={() => setShowDescription(true)}> {t('Structure')}</span>
            {count > 0 && <span ><b className="count-number">{count}</b> {t('Pieces')}</span>}
            <span > <b className="count-number">{sostav}</b> {t('Gram')}.</span>
          </div>
          <h3 className="name">
            <Link to={`/details/${id}`}>
              {finalName}
            </Link>
          </h3>



          <div className="product-price">
            <span className="price">{showCurrencyPrice(finalPrice, currency)} {currency} </span>
            {oldPrice > 0 && <span className="price-before-discount">{showCurrencyPrice(oldPrice, currency)} {currency}</span>}
            {oldPrice > 0 && <span className="percent-discount">{getPercentDiscount(finalPrice, oldPrice)}%</span>}
          </div>
        </div>

        {showAddToCart && addToCart()}

      </div>

    )
  }



  const content = isLoading ? <Spinner /> : renderContent()

  return (
    <div className={className} >
      <div className="products">
        {content}
      </div>
    </div>
  )

}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {
  addItemToMyCart,
  getCartData
}), withNamespaces())(withSnackbar(OneProduct)));


