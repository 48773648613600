import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginAuth } from "../../actions/LoginActionCreators"


class Test extends Component {

  state = {
    loginName: ""
  }

 
  render() {
    const { login: {loginData:{login}}, loginAuth } = this.props;

    const { loginName } = this.state
    return (
      <div>
        <h1>Ваш Логин в редаксе: {login}</h1>
        <input type="text" value={loginName} onChange={(e) => this.setState({ loginName: e.target.value})}/>
        <button onClick={() => loginAuth(loginName)}>Закинуть в редакс</button>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  login: state.LoginReducer,
});

export default withRouter(connect(mapStateToProps, {
  loginAuth,
})(Test));
